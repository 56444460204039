import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import SideBarLinks from '../../../assets/top-bar-links.json'

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  @Output() closeSideBar: EventEmitter<any> = new EventEmitter();
  public readonly soundCloudLogo: string = '/assets/logos/SoundCloud-Logo.png';
  public readonly sideBarItems: any[] = SideBarLinks;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public goToNavLink(link): void {
    this.router.navigate([`/${link}`]);
    this.closeSideBar.emit(true)
  }

}
