<div class="contact-wrapper">
    <div class="contact-info-bubble rounded-container">
        <div class="ian-robo-div">
            <img class="ian-robo" src="../../../assets/images/DCIstandingAround.png">
        </div>
        <div class="inner-grid">
            <div class="box-1">
                <label>All inquiries to: </label>
                <h2>
                    <!-- <img class="gmail-logo" src="../../../assets/images/gmail-logo.svg"> -->
                    <a class="email-link" href="mailto:OpenToadRecords@gmail.com?subject=What should I know about Dompston?" target="_blank">
                        DompstonChecksIn@gmail.com
                    </a>
                </h2>
            </div>
            <div class="box-1">
                <label>Social: </label>
                <h2>
                    <!-- <img class="insta-logo" src="../../../assets/logos/Instagram_Glyph_Gradient_RGB.png"> -->
                    <a class="instagram-link" href="https://instagram.com/dompstonchecksin?utm_medium=copy_link">
                        @Dompstonchecksin
                    </a> 
                </h2>
                <span class="on-instagram"> on Instagram </span>
            </div>
        </div>
    </div>
    
</div>