<div class="top-bar-wrapper">
  <ng-container *ngIf="navbarState$ | async as state; else loading">
    <h1 class="navbar-header fullscreen">
      <ng-container *ngIf="isIntroPage || !state.activeArtist; else artistTitle">
        <a class="nav-title showing" routerLink="">
          <span>Open Toad Records</span>
        </a>
      </ng-container>
      <ng-template #artistTitle>
        <a [class]="'nav-title showing'">
          <span>{{state.activeArtist?.name}}</span>
        </a>
      </ng-template>
    </h1>
    
    <div *ngIf="!isIntroPage" class="full-screen-nav">
      <div *ngFor="let navItem of navItems" class="nav-item" (click)="navigateInternalTo(navItem.link)">
        {{ navItem.name}}
      </div>
    </div>
    
    <div class="side-bar-wrapper">
      <button class="side-bar-icon" (click)="toggleSidebar()">{{isSidebarHidden ? "Open" : "Close" }}</button>
      <app-side-bar [class.hidden]="isSidebarHidden" (closeSideBar)="closeNav($event)"></app-side-bar>
    </div>
    
  </ng-container>
  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>
</div>
  
  