import { Artist } from './artist.interface';
import { AppModule } from './../../app.module';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import artists from '../../../assets/artists.json';

@Injectable({ providedIn: 'root' })
export class ArtistStoreService {
  // Make _artistSource private so it's not accessible from the outside, 
  // expose it as artist$ observable (read-only) instead.
  // Write to _artistSource only through specified store methods below.
  private readonly _artistSource = new BehaviorSubject<Artist[]>([]);
  private readonly _activeArtist = new BehaviorSubject<Artist>(null);

  // Exposed observable (read-only).
  readonly artists$ = this._artistSource.asObservable();
  readonly selectedArtist$ = this._activeArtist.asObservable();

  constructor() {
    this._setAllArtists(artists);
    // !TO BE ADDED: when more than one artist exists
    // let artist = (localStorage.getItem('artist'));
    // if(artist){
    //   this.setActiveArtist(JSON.parse(artist))
    //   console.log(artist)
    // }else{
    // }

    // !TEMPORARY: automatically selects to Dompston Checks In as active artist
    //   will be removed and img click willnavigate to 'Artists(projects)' 
    //   page when more than one artist exists 
      this.setActiveArtist(artists[0]);
  }

  // Get last value without subscribing to the artist$ observable (synchronously).
  public getAllArtists(): Artist[] {
    return this._artistSource.getValue();
  }
    // Get Active Artist without subscribing (synchronously).
  public getActiveArtist(): Artist {
      return this._activeArtist.getValue();
  }

  public getActiveArtistObs(): Observable<Artist> {
      return this._activeArtist;
  }

  private _setAllArtists(artists: Artist[]): void {
    this._artistSource.next(artists);
  }

  public setActiveArtist(artist: Artist): void {
    localStorage.setItem('artist', JSON.stringify(artist))
    this._activeArtist.next(artist);
  }
  public handleDetails(value) {
    localStorage.setItem('artist', value);
  }

  addArtist(artist: Artist): void {
    const artists = [...this.getAllArtists(), artist];
    this._setAllArtists(artists);
  }

  removeArtist(artist: Artist): void {
    const puppies = this.getAllArtists().filter(p => p.id !== artist.id);
    this._setAllArtists(puppies);
  }

  adoptArtist(artist: Artist): void {
    const puppies = this.getAllArtists().map(p =>
      p.id === artist.id ? ({ ...p, ...{ adopted: true } }) : p
    );
    this._setAllArtists(puppies);
  }
}