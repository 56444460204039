<div class="app-container">
    <!-- <div #strangeMouseThing class="strange-mouse">OOO</div> -->
    <div *ngIf="!hasRoute('music')" class="pointer" (click)="navigateInternalTo('/music')">
        <a class="new-music-link" routerLink="/music">
            NEW MUSIC! 
        </a>
    </div>
    <app-top-bar class="top-bar" [topBarLinks]="topBarLinks"></app-top-bar>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- <div class="flash-image" *ngIf="flashImage"></div> -->
