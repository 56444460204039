import { ArtistStoreService } from './../services/artist.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  public showAbout: boolean = false;
  public projectCards: any = [
    {
      name: "Dompston Checks In",
      imagePath: "../../../assets/images/Real-Ass.png"
    }
  ]

  constructor(
    private _artistService: ArtistStoreService
  ) { }

  ngOnInit(): void {
    localStorage.clear();
  }

  public toggleAbout(): void{
    this.showAbout = !this.showAbout
  }

  public go(): void {
    console.log("worked")
    window.open('https://open.spotify.com/artist/3azZAVdZHmtae375vbTMD9?si=mSyey9hWRWmoKPEo4dw7nQ&dl_branch=1')
  }

}
