<div class="side-bar-full">
    <!-- <div *ngFor="let item of sideBarItems" [class]="'nav-item ' + item.name"
        (click)="goToNavLink(item.link)">
        <label>{{item.name}}</label>
    </div> -->
    <div [class]="'nav-item Shows'" (click)="goToNavLink('music')">
        <label> Music</label>
    </div>
    <div [class]="'nav-item Shows'" (click)="goToNavLink('shows')">
        <label> Shows</label>
    </div>
    <div [class]="'nav-item About'" (click)="goToNavLink('about')">
        <label> About</label>
    </div>
    <div [class]="'contact-container'">
        <div [class]="'nav-item Contact'" (click)="goToNavLink('contact')">
            <label> Contact</label>
        </div>
    </div>
</div>
