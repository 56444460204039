<div class="about-wrapper">

    <h1 class="standard-header-purple"> About </h1>
    <ng-container *ngIf="state$ | async as state; else loading">
    <div class="info-container rounded-container">
            <img [class]="!shakeIt ? 'album-cover' : 'album-cover shake'" src="../../../assets/images/dompston_checks_in_cover.jpeg" alt="album cover for dompston checks in">
            
            <div class="about-info-container">
                <p class="about-info">
                    {{ state.about }}
                </p>
                <div class="streaming-info">
                    <label>Available on the following streaming services:</label>
                    <div class="streaming-list-grid">
                        <span (click)="goTo('https://open.spotify.com/track/70XKRSQO1To0YCJPmYdt5C?si=c0830d5d6f2345d8')">
                            <img class="spotify logo" src="../../../assets/logos/Spotify_Icon_RGB_Green.png">
                             Spotify
                            </span>
                        <span (click)="goTo('https://music.apple.com/us/artist/dompston-checks-in/1537284761')">
                            <img class="apple logo" src="../../../assets/logos/Apple_Music_Icon_RGB_sm_073120.svg">
                             Apple Music
                            </span>
                        <span (click)="goTo('https://dompstonchecksin.bandcamp.com/')">
                            <img class="bandcamp logo" src="../../../assets/logos/bandcamp-button-square-black-32.png">
                             Bandcamp
                            </span>
                        <span (click)="goTo('https://www.youtube.com/channel/UCOtbbnqMV-G2OIJXXYbtxlQ')">
                            <img class="youtube logo" src="../../../assets/logos/youtube_social_icon_red.png"> 
                            YouTube
                        </span>
                        <span (click)="goTo('https://soundcloud.com/dompstonchecksin/sets/dompston-checks-in')">
                            <iframe class="soundcloud logo" allowtransparency="true" scrolling="no" frameborder="no" src="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Fdompstonchecksin&color=orange_white&size=32" style="width: 32px; height: 32px;"></iframe> 
                            SoundCloud
                        </span> 
                        <span (click)="goTo('https://www.amazon.com/music/player/albums/B09HJB42VP?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&trackAsin=B09HJCFL37&ref=mp_s_a_1_1&s=dmusic&keywords=Dompston+Checks+In&qid=1648434638&sr=1-1')">
                            <img class="amazon-music logo" src="../../../assets/images/amazon-music.png">
                            Amazon Music
                        </span>
                    </div>
                </div>
            </div>   
        </div>
        <div class="scream-monkey">
            <div class="circle" (mouseover)="shakeTheDompston()" (click)="shakeTheDompston()" (mouseout)="shakeIt=false">
                <img class="st-album-cover" src="../../../assets/images/dompston-ST-cover.jpeg">
            </div>
        </div>

    </ng-container>
    <ng-template #loading>
        Loading...
    </ng-template>
</div>

















<!-- <p class="about-text">The soundtrack for The Age of Kings was directed by Stephen Rippy,
    who has since taken that role for all games in the Age of Empires series. 
    Music for the game was split into two categories. For "in-game" music, 
    Rippy's team took musical elements from a variety of cultures and combined 
    them to create a mixed sound. "Pre-game" music was designed to be unique to the 
    civilization in question. Campaigns based on historical figures would include 
    "a theme that will at least be rooted in [the character's] culture".[43]
</p> -->
