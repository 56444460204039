import { ArtistStoreService } from './../services/artist.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public state$ = new BehaviorSubject<any>(null);
  public destroyed$ = new Subject();
  public shakeIt: boolean = false;

  constructor(
    private _artistService: ArtistStoreService
  ) { }

  ngOnInit(): void {
    this._artistService.getActiveArtistObs().pipe(takeUntil(this.destroyed$)).subscribe((artist) => {
      let isActive = artist ? true : false;
      // console.log(artist)
      this.state$.next( artist);
    })
  }

  public goTo(link: string): void {
    window.open(link);
  }

  public shakeTheDompston(): void {
    this.shakeIt = true;
  }

}
