import { BehaviorSubject } from 'rxjs';
import { Artist } from './../services/artist.interface';
import { Component, OnInit } from '@angular/core';
import { ArtistStoreService } from '../services/artist.service';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss']
})
export class MusicComponent implements OnInit {

  public selectedArtist = new BehaviorSubject<Artist>(null);

  constructor(
    private _artistService: ArtistStoreService
  ) { }

  ngOnInit(): void {
    let activeArtist = this._artistService.getActiveArtist();
    this.selectedArtist.next(activeArtist);
    
  }

}
