import { NavbarState, DefaultNavbarState } from './navbar.interfaces';
import { ArtistStoreService } from './../services/artist.service';
import { Component, Input, OnInit } from '@angular/core';
import {  NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { TopBarItem } from './top-bar.interface';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  @Input() public topBarItem: TopBarItem;
  @Input() public topBarLinks: TopBarItem[];
  @Input() public isIntroPage: boolean = true;

  public isSidebarHidden: boolean = true;
  // public activeArtist = new BehaviorSubject<Artist>(null);
  public navbarState$ = new BehaviorSubject<NavbarState>(DefaultNavbarState);
  private destroyed$ = new Subject();

  public navItems: any = [
    {
      name: "Music",
      link: "music"
    },
    {
      name: "Shows",
      link: "shows"
    },
    {
      name: "About",
      link: "about"
    },
    // {
    //   name: "Merch",
    //   link: "merch"
    // },
    {
      name: "Contact",
      link: "contact"
    }
  ]

  // Desired banner on Artists page:
  // Music, Shows, Merch, About 
  public readonly soundCloudLogo: string = '/assets/logos/SoundCloud-Logo.png';

  constructor(
    private router: Router,
    private _artistService: ArtistStoreService
  ) {}

  ngOnInit(): void {
    this.router.events
    .pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      takeUntil(this.destroyed$),
    )
    .subscribe((event: NavigationStart) => {
       // handle navigation start event
        this.isIntroPage = event.url == '/' ? true : false;
    });
    this._artistService.getActiveArtistObs().pipe(takeUntil(this.destroyed$)).subscribe((artist) => {
      let isActive = artist ? true : false;
      // console.log(artist)
      this.navbarState$.next({isArtistActive: isActive, activeArtist: artist});
    })
  }

  public onMusicSiteClick(site):void{
    window.open(`${site}`)
  }

  public navigateInternalTo(link: string): void {
    this.router.navigate([`/${link}`])
  }
  public navigateTo(link: string): void {
    window.open(link)
  }

  public toggleSidebar():void{
    this.isSidebarHidden = !this.isSidebarHidden;
    this.isSidebarHidden ? this.closeNav() : !this.isSidebarHidden;
  }

  public closeNav(): void {
    this.isSidebarHidden = true;
    console.log("closin'")
  }

  public ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.destroyed$.next();
  }

}


