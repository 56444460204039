<div class="shows-wrapper">
    
    <h1 class="standard-header-orange">Live Shows</h1>
    <div class="shows-list rounded-container">
        <p>
            <span class="show-date">March 18th 2023</span> 
            <span class="show-info"> - Lazydaze - 4416 Fairmont Parkway - Pasadena - Pasadena, TX</span>
        </p>
        <p className="next-up">
            <span class="show-date">Feb 25th 2023</span> 
            <span class="show-info"> - Tamar's House</span>
        </p>
        <p>
            <span class="show-date expired">June 19th 2022 </span> 
            <span class="show-info expired"> - Black Magic Social Club - Houston TX - Doors 6 PM - </span>
        </p>
        <p>
            <span class="show-date expired">May 28th 2022 </span> 
            <span class="show-info expired" > - House Show - 9522 Kell Circle - Door 7 - Music 7:30 - $5 entry - </span>
        </p>
        <p>
            <span class="show-date expired" >April 21st 2022 </span> -
            <span class="show-info expired" >Super Happy Funland w/ Beneath The Trees, Blue Tongue, RINA - </span>
        </p>
    </div>
</div>

<!-- <div class="kell-flyer-container">
    <img class="kell-flyer borderBlink" src="../../../assets/ShowFlyers/blackMagicFlyer.png"/>
</div> -->
