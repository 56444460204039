<div class="homepage-wrapper">

  <div class="spacer">
  </div>

  <div class="mere-survival-div">
    <a routerLink="/about">
      <img class="mere-survival-img" src="../../../assets/images/OpenToad3.png">
    </a>
  </div>

  <div class="homepage-footer">
    <p class="footer-content">
      <a target="_blank" rel="noopener noreferrer" href="mailto:OpenToadRecords@gmail.com?subject=What should I know about Dompston?">
        OpenToadRecords@gmail.com
      </a>
      <span>Houston Tx</span>
    </p>
  </div>

</div>
