import { LocationComponent } from './components/location/location.component';
import { ContactComponent } from './components/contact/contact.component';
import { ShowsComponent } from './components/shows/shows.component';
import { AboutComponent } from './components/about/about.component';
import { MusicComponent } from './components/music/music.component';
import { ArtistStoreService } from './components/services/artist.service';
import { HomePageComponent } from './components/home-page/home-page.component';
import { HistoryComponent } from './components/history/history.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'shows', component: ShowsComponent },
  // { path: 'projects', component: ProjectsComponent }, To Be Added when multiple projects exist
  { path: 'music', component: MusicComponent },
  { path: 'about', component: AboutComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'location', component: LocationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ ArtistStoreService]
})
export class AppRoutingModule { }
