<div class="history-wrapper">
    <div class="history-info-wrapper1">
        <p class="history-info-txt">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum iusto, laborum veritatis vitae quos repellat eum pariatur aut dolores. Praesentium dolor magni officiis illum accusantium voluptatibus eum tempore voluptate est.
        </p>
    </div>
    <div class="history-info-wrapper2">
        <p class="history-info-txt">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum iusto, laborum veritatis vitae quos repellat eum pariatur aut dolores. Praesentium dolor magni officiis illum accusantium voluptatibus eum tempore voluptate est.
        </p>
    </div>
    <div class="history-info-wrapper3">
        <p class="history-info-txt">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum iusto, laborum veritatis vitae quos repellat eum pariatur aut dolores. Praesentium dolor magni officiis illum accusantium voluptatibus eum tempore voluptate est.
        </p>
    </div>
</div>