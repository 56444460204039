import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import TopBarLinks from '../assets/top-bar-links.json'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {


    constructor(
        private router: Router,
    ) {}

    public secretEl: HTMLElement;

    hasRoute(route: string) {
        return this.router.url.includes(route);
    }
    // Saving this to do weird mouse stuff someday
    // @HostListener('mousemove', ['$event']) 
    // public onMouseMove(e) {
    //   if(this.secretEl){
    //     let xPos = parseInt(e.clientX);
    //     let yPos = parseInt(e.clientY);
    //     console.log(yPos);

    //     if(xPos > 0){
    //         this.secretEl.style.right = `${xPos-5}px`;
    //     }
    //     if(yPos > 1000){
    //         // this.secretEl.style.right = `${yPos-5}px`;
    //     }
    //     // this.secretEl.style.top = `${xPos}px`;
    //     // this.secretEl.style.right = `${yPos}px`;
    //   }
    // }

    @ViewChild('strangeMouseThing') secretEl1: any;  

    public flashImage: boolean = false
    public topBarLinks: any[] = TopBarLinks;
    
    title = 'open-toad-records';

      public ngOnInit(): void {
        setInterval(() => {
        setTimeout( () => {
            this.flashImage = !this.flashImage;
        }, 1000)
            this.flashImage = !this.flashImage;
        }, 120000)
    }

    // public ngAfterViewInit() {
    //     this.secretEl = this.secretEl1.nativeElement;
    // }  

    // public mouseMoved(event: MouseEvent) {
    //     let xPos= event.clientX;
    //     let yPos= event.clientY;

    //     let coors = "Coordinate (X) : " + xPos + " " + "pixels <br>Coordinate (Y) : " + yPos + " " + "pixels";
    //     console.log(coors);
    // }

    public navigateInternalTo(link: string): void {
        this.router.navigate([`/${link}`])
      }
}
