import { ArtistStoreService } from './../services/artist.service';
import { Component, OnInit } from '@angular/core';
import artistsData from '../../../assets/artists.json';
import { Artist } from '../services/artist.interface';
import { Router } from '@angular/router';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public artists: Artist[] = artistsData;

  constructor(
    private _artistService: ArtistStoreService,
    private router: Router
  ) { }

  ngOnInit(): void {
    
  }

  public logArtist(): void {
    let artist = this._artistService.getAllArtists();
    console.log(artist)
  }

  public selectArtist(artist: Artist): void {
    this._artistService.setActiveArtist(artist);
    this.router.navigate([`/music`]);
  }

}
