import { Artist } from "../services/artist.interface";

export interface NavbarState {
    activeArtist?: Artist;
    isArtistActive: boolean;
}

export const DefaultNavbarState: NavbarState = {
    activeArtist: null,
    isArtistActive: false
}